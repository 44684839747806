/* Responsive pagination button styles */
.pagination-btn {
  background-color: #1f2937;
  color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
}

.pagination-btn:disabled {
  background-color: #666;
  cursor: not-allowed;
}

/* Pagination layout */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
}

@media (max-width: 768px) {
  /* Adjust pagination button font size for small screens */
  .pagination-btn {
    font-size: 12px;
    padding: 6px 12px; /* Slightly smaller padding on mobile */
  }
}
